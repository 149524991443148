var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AuthorRank"},[_c('div',{staticClass:"title"},[_vm._v("作者榜")]),_c('div',{staticClass:"search_box"},[_c('div',{staticClass:"search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchValue),expression:"searchValue"}],staticClass:"inp",attrs:{"type":"text","placeholder":"让每个人都写得更好"},domProps:{"value":(_vm.searchValue)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.searchValue=$event.target.value}}}),_c('div',{staticClass:"search_btn",on:{"click":function($event){return _vm.search()}}},[_c('img',{attrs:{"src":require('assets/images/idiom.png'),"alt":""}}),_c('img',{attrs:{"src":require('assets/images/included_search.png'),"alt":""}})])])]),_c('div',{staticClass:"author_box"},_vm._l((_vm.List),function(item){return _c('div',{key:item.id,staticClass:"author_item"},[_c('div',{staticClass:"info_box"},[_c('div',{staticClass:"ranking_img"},[(item.rank == 1)?_c('img',{attrs:{"src":require('assets/images/library/Thefirst.png'),"alt":""}}):_vm._e(),(item.rank == 2)?_c('img',{attrs:{"src":require('assets/images/library/Thesecond.png'),"alt":""}}):_vm._e(),(item.rank == 3)?_c('img',{attrs:{"src":require('assets/images/library/Thethird.png'),"alt":""}}):_vm._e(),_vm._v(" "+_vm._s(item.rank > 3 ? item.rank : "")+" ")]),_c('div',{staticClass:"img_box",on:{"click":function($event){return _vm.$router.push({
              name: 'authorDetails',
              params: {
                userId: item.userId,
              },
            })}}},[_c('img',{attrs:{"src":item.headImg,"alt":""},on:{"error":function($event){return _vm.imgError()}}})]),_c('div',{staticClass:"info",on:{"click":function($event){return _vm.$router.push({
              name: 'authorDetails',
              params: {
                userId: item.userId,
              },
            })}}},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.author))]),_c('div',{staticClass:"works_sum"},[_c('div',{staticClass:"sum"},[_vm._v(" 作品数量 : "+_vm._s(item.worksCount)+"篇"+_vm._s(item.wordsNum)+"字 ")]),_c('div',{staticClass:"earnings"},[_vm._v("收益: "+_vm._s(item.cashIncome))])])])]),(item.focusType === 0)?_c('div',{staticClass:"focus_btn Focus",on:{"click":function($event){return _vm.focusClick(item, 1)}}},[_vm._v(" 关注 ")]):_c('div',{staticClass:"focus_btn",on:{"click":function($event){return _vm.focusClick(item, 2)}}},[_c('img',{attrs:{"src":item.focusType == 1
              ? require('assets/images/library/icon_1.png')
              : require('assets/images/library/icon.png'),"alt":""}}),_vm._v(" "+_vm._s(item.focusType == 1 ? "已关注" : "互相关注")+" ")])])}),0),(_vm.total == 0)?_c('img',{staticClass:"kong",attrs:{"src":require('assets/images/kong.png'),"alt":""}}):_vm._e(),(_vm.total > 10)?_c('div',{staticClass:"paging_box"},[_c('el-pagination',{attrs:{"layout":"total, prev, pager, next, jumper","pager-count":5,"current-page":_vm.page,"page-size":10,"total":_vm.total},on:{"current-change":_vm.currentChange}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }