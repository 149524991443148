<template>
  <div class="AuthorRank">
    <div class="title">作者榜</div>
    <div class="search_box">
      <div class="search">
        <input
          class="inp"
          v-model="searchValue"
          type="text"
          placeholder="让每个人都写得更好"
          @keyup.enter="search"
        />
        <div class="search_btn" @click="search()">
          <img :src="require('assets/images/idiom.png')" alt="" />
          <img :src="require('assets/images/included_search.png')" alt="" />
        </div>
      </div>
    </div>
    <!-- 作者list -->
    <div class="author_box">
      <div class="author_item" v-for="item in List" :key="item.id">
        <div class="info_box">
          <div class="ranking_img">
            <img
              v-if="item.rank == 1"
              :src="require('assets/images/library/Thefirst.png')"
              alt=""
            />
            <img
              v-if="item.rank == 2"
              :src="require('assets/images/library/Thesecond.png')"
              alt=""
            />
            <img
              v-if="item.rank == 3"
              :src="require('assets/images/library/Thethird.png')"
              alt=""
            />

            {{ item.rank > 3 ? item.rank : "" }}
          </div>

          <div
            class="img_box"
            @click="
              $router.push({
                name: 'authorDetails',
                params: {
                  userId: item.userId,
                },
              })
            "
          >
            <img :src="item.headImg" @error="imgError()" alt="" />
          </div>

          <div
            class="info"
            @click="
              $router.push({
                name: 'authorDetails',
                params: {
                  userId: item.userId,
                },
              })
            "
          >
            <div class="name">{{ item.author }}</div>
            <div class="works_sum">
              <div class="sum">
                作品数量 : {{ item.worksCount }}篇{{ item.wordsNum }}字
              </div>

              <div class="earnings">收益: {{ item.cashIncome }}</div>
            </div>
          </div>
        </div>

        <div
          v-if="item.focusType === 0"
          class="focus_btn Focus"
          @click="focusClick(item, 1)"
        >
          关注
        </div>
        <div v-else class="focus_btn" @click="focusClick(item, 2)">
          <img
            :src="
              item.focusType == 1
                ? require('assets/images/library/icon_1.png')
                : require('assets/images/library/icon.png')
            "
            alt=""
          />
          {{ item.focusType == 1 ? "已关注" : "互相关注" }}
        </div>
      </div>
    </div>

    <img
      v-if="total == 0"
      class="kong"
      :src="require('assets/images/kong.png')"
      alt=""
    />

    <!-- 分页 -->
    <div class="paging_box" v-if="total > 10">
      <el-pagination
        layout="total, prev, pager, next, jumper"
        :pager-count="5"
        :current-page="page"
        :page-size="10"
        @current-change="currentChange"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { authorList, focus } from "api/library";
export default {
  name: "AuthorRank",
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      page: 1,
      total: 0,
      List: [],
      searchValue: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    currentChange(val) {
      this.page = val;
      this.getlist();
    },

    imgError() {
      return require("assets/images/user.png");
    },

    search() {
      this.getlist();
    },

    async getlist() {
      const data = {
        page: this.page,
        limit: 10,
        author: this.searchValue,
      };

      const res = await authorList(data);

      if (res.code != 200) {
        this.List = [];
        this.$message.error(res.message);
        return;
      }

      this.total = res.data.total;
      this.List = res.data.data.map((item) => {
        if (!item.headImg) {
          item.headImg = require("assets/images/user.png");
          return item;
        } else {
          return item;
        }
      });
    },

    // 取消关注
    async focusClick(item, type) {
      const data = {
        focusId: item.userId,
        type,
      };

      const res = await focus(data);
      if (res.code != 200) {
        this.$message.error(res.message);
        return;
      }

      this.getlist();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getlist();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang="scss" scoped>
.AuthorRank {
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 51px);
  border-left: 1px solid #f7f7f7;
  flex: 1;
  padding: 0 20px;

  .search_box {
    margin: 20px auto;
    width: 60%;
    .search {
      overflow: hidden;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      // padding: 5px 5px 5px 0;

      border-radius: 3px;
      border: 1px solid #ff6900;

      .search_left {
        .el-select {
          ::v-deep .el-input {
            .el-input__inner {
              width: 100px;
              border: 0px;
            }
          }
        }
      }

      .inp {
        height: 26px;
        border-left: 1px solid #e4e4e4;
        padding-left: 20px;
        font-size: $font-size-medium;
        font-family: PingFang SC;
        font-weight: 500;
        color: $color-text-default;
        flex: 1;
      }
      .search_btn {
        height: 40px;
        padding: 0 10px;
        background: #ff6900;

        @include flex-center();
        // border-radius: 0 5px 5px 0;
        cursor: pointer;
        > img:first-child {
          width: 52px;
          height: 22px;
          margin: 0 10px;
        }
      }
    }
  }

  .title {
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  // 作者list
  .author_box {
    .author_item {
      border-bottom: 1px solid #f5f5f5;
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .info_box {
        display: flex;

        .ranking_img {
          width: 34px;
          margin-right: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          img {
            width: 100%;
          }
        }

        .img_box {
          cursor: pointer;
          margin-right: 20px;
          img {
            width: 63px;
            border-radius: 50%;
          }
        }

        .info {
          cursor: pointer;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .name {
            font-size: 16px;
          }
          .works_sum {
            font-size: 12px;
            display: flex;

            .sum {
              width: 180px;
            }

            .earnings {
              margin-left: 30px;
            }
          }
        }
      }
      .focus_btn {
        cursor: pointer;
        height: 28px;
        line-height: 28px;
        padding: 0 7px;
        border: 1px solid #666666;
        border-radius: 14px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        font-size: 12px;
        img {
          width: 12px;
          margin-right: 3px;
        }
      }

      .Focus {
        cursor: pointer;
        background-color: #ff6900;
        color: #fff;
        padding: 0 20px;
        border: 0;
      }
    }
  }

  // 分页
  .paging_box {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  // 空
  .kong {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
